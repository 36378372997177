<template>
  <t-form-card>
    <template #header>
      <h4>
        {{ $t('global.headings.basic_info') }}
      </h4>
    </template>
    <validation-observer ref="validator" v-slot="{ invalid, handleSubmit }" tag="div">
      <t-form @submit.native.prevent="handleSubmit(submit)">
        <t-grid>
          <t-grid-row>
            <t-grid-cell>
              <t-validation-wrapper
                vid="firstname"
                :name="$t('fields.first_name')"
                :label="$t('fields.first_name')"
                rules="required"
              >
                <t-input
                  v-model="form.firstname"
                  type="text"
                  :placeholder="$t('fields.first_name')"
                >
                  <t-icon slot="right" :icon="['fas', 'user']" />
                </t-input>
              </t-validation-wrapper>
            </t-grid-cell>
            <t-grid-cell>
              <t-validation-wrapper
                vid="lastname"
                :name="$t('fields.last_name')"
                :label="$t('fields.last_name')"
                rules="required"
              >
                <t-input v-model="form.lastname" type="text" :placeholder="$t('fields.last_name')">
                  <t-icon slot="right" :icon="['fas', 'user']" />
                </t-input>
              </t-validation-wrapper>
            </t-grid-cell>
          </t-grid-row>
        </t-grid>

        <t-validation-wrapper vid="gender" :name="$t('fields.gender')" rules="required|string">
          <gender-select v-model="form.gender" />
        </t-validation-wrapper>

        <t-form-element>
          <t-button-loading :loading="loading" native-type="submit" :disabled="invalid">
            {{ $t('global.actions.save') }}
          </t-button-loading>
        </t-form-element>
      </t-form>
    </validation-observer>
  </t-form-card>
</template>
<script>
import Vue from 'vue';
import User from '~/models/User';
import GenderSelect from '~/components/EnumSelect/GenderSelect.vue';
import TValidationWrapper from '~/components/TValidationWrapper.vue';
import TFormCard from '~/components/TFormCard.vue';

export default Vue.extend({
  components: {
    TFormCard,
    TValidationWrapper,
    GenderSelect,
  },

  data() {
    return {
      form: {
        firstname: this.$auth.user.firstname,
        lastname: this.$auth.user.lastname,
        gender: this.$auth.user.gender,
      },

      loading: false,
    };
  },

  methods: {
    async submit() {
      this.loading = true;
      try {
        const user = await new User({ id: this.$auth.user.id, ...this.form }).patch();
        this.$auth.commit('PATCH_USER', user);
        await this.$notify.success(this.$t('notifications.account.updated'));
      } catch (e) {
        this.$axios.handleError(e, this.$refs.validator);
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>
