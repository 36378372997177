<template>
  <t-form-card>
    <template #header>
      <h4>{{ $t('global.headings.more_info') }}</h4>
    </template>
    <validation-observer ref="validator" v-slot="{ invalid, handleSubmit }" tag="div">
      <t-form @submit.native.prevent="handleSubmit(submit)">
        <t-validation-wrapper
          vid="date_of_birth"
          :name="$t('fields.date_of_birth')"
          rules="required|age:16"
        >
          <t-datepicker v-model="form.date_of_birth">
            <t-icon slot="right" icon="baby" />
          </t-datepicker>
        </t-validation-wrapper>

        <t-validation-wrapper vid="phonenumber" :name="$t('fields.phonenumber')" rules="required">
          <t-phonenumber v-model="form.meta.phonenumber" />
        </t-validation-wrapper>

        <t-validation-wrapper
          vid="graduation_type_id"
          :name="$t('fields.graduation_type')"
          rules="required"
        >
          <GraduationTypeSelect v-model="form.graduation_type_id" />
        </t-validation-wrapper>

        <t-form-element>
          <t-button-loading :loading="loading" native-type="submit" :disabled="invalid">
            {{ $t('global.actions.save') }}
          </t-button-loading>
        </t-form-element>
      </t-form>
    </validation-observer>
  </t-form-card>
</template>
<script>
import Vue from 'vue';
import TValidationWrapper from '~/components/TValidationWrapper';
import TFormCard from '~/components/TFormCard';
import Applicant from '~/models/Applicant';
import TDatepicker from '~/components/TDatepicker';
import GraduationTypeSelect from '~/components/ResourceSelect/GraduationTypeSelect.vue';

export default Vue.extend({
  components: {
    TFormCard,
    TValidationWrapper,
    TDatepicker,
    GraduationTypeSelect,
  },

  data() {
    return {
      form: {
        date_of_birth: this.$auth.me?.date_of_birth,
        graduation_type_id: this.$auth.me?.graduation_type_id,

        meta: {
          phonenumber: this.$auth.me?.meta?.phonenumber ?? '',
        },
      },

      loading: false,
    };
  },

  methods: {
    async submit() {
      this.loading = true;
      try {
        const applicant = await new Applicant({ id: this.$auth.me.id, ...this.form }).patch();
        this.$auth.commit('PATCH_ME', applicant);
        await this.$notify.success(this.$t('notifications.account.updated'));
      } catch (e) {
        this.$axios.handleError(e, this.$refs.validator);
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>
