<template>
  <t-form-card>
    <template #header>
      <h4>
        {{ $t('global.headings.more_info') }}
      </h4>
    </template>
    <validation-observer ref="validator" v-slot="{ invalid, handleSubmit }" tag="div">
      <t-form @submit.native.prevent="handleSubmit(submit)">
        <t-validation-wrapper
          vid="meta.phonenumber"
          :name="$t('fields.phonenumber')"
          rules="required|phonenumber"
        >
          <t-phonenumber v-model="form.meta.phonenumber" />
        </t-validation-wrapper>

        <t-validation-wrapper
          vid="meta.position"
          :name="$t('fields.position')"
          rules="required|string|min:2|max:255"
        >
          <t-input v-model="form.meta.position" type="text" :placeholder="$t('fields.position')" />
        </t-validation-wrapper>

        <t-form-element>
          <t-button-loading :loading="loading" native-type="submit" :disabled="invalid">
            {{ $t('global.actions.save') }}
          </t-button-loading>
        </t-form-element>
      </t-form>
    </validation-observer>
  </t-form-card>
</template>
<script>
import Vue from 'vue';
import TValidationWrapper from '~/components/TValidationWrapper.vue';
import TFormCard from '~/components/TFormCard.vue';
import Employee from '~/models/Employee';

export default Vue.extend({
  components: {
    TFormCard,
    TValidationWrapper,
  },

  data() {
    return {
      form: {
        meta: {
          phonenumber: this.$auth.me?.meta?.phonenumber ?? '',
          position: this.$auth.me?.meta?.position,
        },
      },

      loading: false,
    };
  },

  methods: {
    async submit() {
      this.loading = true;
      try {
        const applicant = await new Employee({ id: this.$auth.me.id, ...this.form }).patch();
        this.$auth.commit('PATCH_ME', applicant);
        await this.$notify.success(this.$t('notifications.account.updated'));
      } catch (e) {
        this.$axios.handleError(e, this.$refs.validator);
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>
