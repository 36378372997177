<template>
  <t-form-card>
    <template #header>
      <h4>{{ $t('fields.address.address') }}</h4>
    </template>
    <validation-observer ref="validator" v-slot="{ invalid, handleSubmit }" tag="div">
      <t-form @submit.native.prevent="handleSubmit(submit)">
        <Address v-model="form.address" />

        <t-form-element>
          <t-button-loading :loading="loading" native-type="submit" :disabled="invalid">
            {{ $t('global.actions.save') }}
          </t-button-loading>
        </t-form-element>
      </t-form>
    </validation-observer>
  </t-form-card>
</template>
<script>
import Vue from 'vue';
import Address from '~/components/Fields/Address';
import TFormCard from '~/components/TFormCard';
import Applicant from '~/models/Applicant';

export default Vue.extend({
  components: {
    TFormCard,
    // eslint-disable-next-line vue/no-reserved-component-names
    Address,
  },

  data() {
    return {
      form: {
        address: {
          line_1: this.$auth.me?.address?.line_1,
          line_2: this.$auth.me?.address?.line_2,
          postcode: this.$auth.me?.address?.postcode,
          city: this.$auth.me?.address?.city,
          country: this.$auth.me?.address?.country,
        },
      },

      loading: false,
    };
  },

  methods: {
    async submit() {
      this.loading = true;
      try {
        const applicant = await new Applicant({ id: this.$auth.me.id, ...this.form }).patch();
        this.$auth.commit('PATCH_ME', applicant);
        await this.$notify.success(this.$t('notifications.account.updated'));
      } catch (e) {
        this.$axios.handleError(e, this.$refs.validator);
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>
