<template>
  <t-page>
    <h3 slot="header">
      {{ $t('pages.account.general_settings.heading') }}
    </h3>
    <t-grid>
      <t-grid-row>
        <t-grid-cell>
          <form-card-basic-info />
        </t-grid-cell>
      </t-grid-row>
      <t-grid-row v-if="$auth.userType === UserTypeEnum.APPLICANT">
        <t-grid-cell>
          <form-card-more-info-applicant />
        </t-grid-cell>
      </t-grid-row>
      <t-grid-row v-if="$auth.userType === UserTypeEnum.APPLICANT">
        <t-grid-cell>
          <form-card-address-applicant />
        </t-grid-cell>
      </t-grid-row>
      <t-grid-row v-if="$auth.userType === UserTypeEnum.EMPLOYEE">
        <t-grid-cell>
          <form-card-more-info-employee />
        </t-grid-cell>
      </t-grid-row>
    </t-grid>
  </t-page>
</template>
<script>
import Vue from 'vue';
import FormCardBasicInfo from '~/pages/account/partials/FormCardBasicInfo';
import UserTypeEnum from '~/enums/UserTypeEnum';
import FormCardMoreInfoApplicant from '~/pages/account/partials/FormCardMoreInfoApplicant';
import FormCardAddressApplicant from '~/pages/account/partials/FormCardAddressApplicant';
import FormCardMoreInfoEmployee from '~/pages/account/partials/FormCardMoreInfoEmployee';

export default Vue.extend({
  components: {
    FormCardMoreInfoEmployee,
    FormCardMoreInfoApplicant,
    FormCardAddressApplicant,
    FormCardBasicInfo,
  },

  data: () => ({
    UserTypeEnum,
  }),
});
</script>
